import request from '@/utils/requestV2'
const qs = require('qs')

/**
 * 作业令拆解
 * @param {Object} data
 * @returns
 */
export function dismantleWorkday (data) {
  return request.post('/ooh-msp/v1/workdaySchedule/dismantleWorkday', qs.stringify(data))
}

/**
 * 分页查询施工计划表的任务明细
 * @param {Object} data
 * @returns
 */
export function getTableTaskPage (data) {
  return request.post('/ooh-msp/v1/workdaySchedule/getTableTaskPage', qs.stringify(data))
}

/**
 * 施工计划按位置维度汇总
 * @param {Object} data
 * @returns
 */
export function getTaskitemPositionGather (data) {
  return request.post('/ooh-msp/v1/workdaySchedule/getTaskitemPositionGather', qs.stringify(data))
}

/**
 * 分页查询作业令排期列表
 * @param {Object} data
 * @returns
 */
export function getWorkdayPage (data) {
  return request.post('/ooh-msp/v1/workdaySchedule/getWorkdayPage', qs.stringify(data))
}

/**
 * 分页查询施工计划任务明细
 * @param {Object} data
 * @returns
 */
export function getWorkdayTaskPage (data) {
  return request.post('/ooh-msp/v1/workdaySchedule/getWorkdayTaskPage', qs.stringify(data))
}

/**
 * 设置代班人员
 * @param {Object} data
 * @returns
 */
export function setWorkdayUser (data) {
  return request.post('/ooh-msp/v1/workdaySchedule/setWorkdayUser', qs.stringify(data))
}

/**
 * 施工计划工作时段、施工人数及备注等信息更新
 * @param {Object} data
 * @returns
 */
export function updateWorkday (data) {
  return request.post('/ooh-msp/v1/workdaySchedule/updateWorkday', qs.stringify(data))
}

/**
 * 编辑作业日期
 * @param {Object} data
 * @returns
 */
export function modifyActionDate (data) {
  return request.post('/ooh-msp/v1/workdaySchedule/modifyActionDate', qs.stringify(data))
}

/**
 * 计算代班人员与站点的距离
 * @param {Object} data
 * @returns
 */
export function getUserToStationFarList (data) {
  return request.post('/ooh-msp/v1/workdaySchedule/getUserToStationFarList', qs.stringify(data))
}

/**
 * 创建车场或车站计划
 * @param {Object} data
 * @returns
 */
export function createWorkday (data) {
  return request.post('/ooh-msp/v1/workdaySchedule/createWorkday', qs.stringify(data))
}

/**
 * 查询待加入作业计划的任务集合
 * @param {Object} data
 * @returns
 */
export function getTaskitemList (data) {
  return request.post('/ooh-msp/v1/workdaySchedule/getTaskitemList', qs.stringify(data))
}

/**
 * 添加任务到作业计划。不存在作业计划则新增(未提前创建作业计划-站点类)
 * @param {Object} data
 * @returns
 */
export function addTaskToWorkday (data) {
  return request.post('/ooh-msp/v1/workdaySchedule/addTaskToWorkday', qs.stringify(data))
}

/**
 * 将施工计划从施工计划表中移除
 * @param {Object} data
 * @returns
 */
export function removeWorkday (data) {
  return request.post('/ooh-msp/v1/workdaySchedule/removeWorkday', qs.stringify(data))
}

/**
 * 将任务从施工计划中移除
 * @param {Object} data
 * @returns
 */
export function removeTaskitem (data) {
  return request.post('/ooh-msp/v1/workdaySchedule/removeTaskitem', qs.stringify(data))
}

/**
 * 调整任务作业日期 期望作业日期。作业令申请成功后，再调整任务真实作业日期
 * @param {Object} data
 * @returns
 */
export function modifyTaskitemActionDate (data) {
  return request.post('/ooh-msp/v1/workdaySchedule/modifyTaskitemActionDate', qs.stringify(data))
}

/**
 * 创建车场计划
 * @param {Object} data
 * @returns
 */
export function createTrainWorkday (data) {
  return request.post('/ooh-msp/v1/workdaySchedule/createTrainWorkday', qs.stringify(data))
}

/**
 * 查询列车类型待加入作业计划的任务集合
 * @param {Object} data
 * @returns
 */
export function getTrainTaskitemList (data) {
  return request.post('/ooh-msp/v1/workdaySchedule/getTrainTaskitemList', qs.stringify(data))
}

/**
 * 将任务添加到已存在的作业计划(车场类)
 * @param {Object} data
 * @returns
 */
export function addTaskToExitWorkday (data) {
  return request.post('/ooh-msp/v1/workdaySchedule/addTaskToExitWorkday', qs.stringify(data))
}
